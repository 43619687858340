import { Dispatch } from 'redux';

import {
  ConversationDocument,
  isHiddenField,
  NotificationsField,
  ProcessedConversation,
  setExpiresAtTimestamp,
} from 'dashboard/models/Conversation';

import {
  baseMessageDocument,
  DecryptedMessagePayload,
  EncryptedMessageDocument,
  FileAttachment,
  MessageState,
  ProcessedMessage,
  URLAttachment,
} from 'dashboard/models/Message';

import { User, UserModelFields } from 'dashboard/models/User';

import {
  authorizeUser,
  decryptFile,
  decryptMessage,
  encryptMessage,
} from 'dashboard/services/XQSDK';

import axios, { AxiosError } from 'axios';
import {
  AvailableCollections,
  FIREBASE_FIRESTORE,
  FirestoreTimestamp,
  FirestoreDocumentData,
  FirestoreObserver,
  FirestoreSort,
  createDocumentId,
  fetchUser,
  getDocumentById,
  setFirebaseTimestamp,
  createFirestoreSubCollectionDocument,
  updateFirestoreDocument,
  incrementFieldValue,
} from 'dashboard/services/firebase';
import { env } from 'env';
import { AuthenticationController } from 'auth/AuthenticationController';
import {
  setMessageLoaders,
  setProcessedMessages,
} from 'store/reducers/messages';

import { detectURLs, fetchURLsMetadata } from './detectURLs';

/**
 * A function utilized to stream messages from a given conversation
 *
 * @param conversationId - ID of the current conversation to stream messages from
 * @param sort - sort by 'asc' (ascending) or 'desc' (descending)
 * @param limit - number of messages to be displayed
 * @param observer - a listener for DocumentSnapshot events
 * @returns void
 */
export const streamConversationMessages = (
  conversationId: string,
  sort: FirestoreSort,
  limit: number | null,
  observer: FirestoreObserver
) => {
  const conversationCollectionReference = FIREBASE_FIRESTORE.collection(
    AvailableCollections.CONVERSATIONS
  )
    .doc(conversationId)
    .collection(AvailableCollections.MESSAGES)
    .orderBy('date', sort);

  if (limit) {
    return conversationCollectionReference.limit(limit).onSnapshot(observer);
  }

  return conversationCollectionReference.onSnapshot(observer);
};

/**
 * A function utilized to stream expiry messages from a given conversation
 *
 * @param conversationId - ID of the current conversation to stream messages from
 * @param observer - a listener for DocumentSnapshot events
 * @returns void
 */
export const streamConversationSettings = (
  conversationId: string,
  observer: FirestoreObserver
) => {
  return FIREBASE_FIRESTORE.collection(AvailableCollections.CONVERSATIONS)
    .where('id', '==', conversationId)
    .onSnapshot(observer);
};

export const streamConversations = (
  sort: FirestoreSort,
  userId: string,
  observer: FirestoreObserver
) => {
  return FIREBASE_FIRESTORE.collection(AvailableCollections.CONVERSATIONS)
    .where('recipients', 'array-contains-any', [userId])
    .onSnapshot(observer);
};

/**
 * A function utilized to stream anonymous conversations by looking up a particular conversation
 *
 * @param conversationId - ID of the current conversation to stream messages from
 * @param sort - sort by 'asc' (ascending) or 'desc' (descending)
 * @param observer - a listener for DocumentSnapshot events
 * @returns void
 */
export const streamGuestConversations = (
  conversationId: string,
  sort: FirestoreSort,
  observer: FirestoreObserver
) => {
  return FIREBASE_FIRESTORE.collection(AvailableCollections.CONVERSATIONS)
    .where('id', '==', conversationId)
    .onSnapshot(observer);
};

/**
 * A function utilized to query recipient emails
 *
 * @param recipientEmails - list or recipient emails
 * @returns document
 */

export const queryRecipientEmails = async (recipientEmails: string[]) =>
  (await getDocumentById(
    'email',
    recipientEmails,
    AvailableCollections.USERS
  )) as User[];

/**
 * A Firestore Query for finding existing conversations by conversation ID
 * @param conversationId - string
 * @returns User[] | []
 */
export const checkForExistingConversationWithConversationId = async (
  conversationId: string
) => {
  const response = await FIREBASE_FIRESTORE.collection(
    AvailableCollections.CONVERSATIONS
  )
    .doc(conversationId)
    .get();
  return response;
};

/**
 * A Firestore Query for finding existing conversations with same recipient list as new `ConversationDocument`
 * @param sortedRecipientIds - string[]
 * @returns User[] | []
 */
export const checkForExistingConversationWithRecipients = (
  sortedRecipientIds: string[],
  workspaceId: string
) =>
  FIREBASE_FIRESTORE.collection(AvailableCollections.CONVERSATIONS)
    .where('sortedRecipients', '==', sortedRecipientIds)
    .where('workspaceId', '==', workspaceId)
    .get()
    .then((res) => res.docs.map((doc) => doc.data() as ConversationDocument));

/**
 * A Firestore setter for updating an existing conversation
 * @param existingConversation - string[]
 * @param currentUser: User
 * @returns document
 */
export const setExistingConversation = (
  existingConversation: ConversationDocument,
  currentUser: User
) =>
  FIREBASE_FIRESTORE.collection(AvailableCollections.CONVERSATIONS)
    .doc(existingConversation.id)
    .set(
      {
        isHidden: {
          [currentUser.id]: {
            now: false,
            date: new Date(),
          },
        },
      },
      { merge: true }
    );

/**
 * A function utilized to update the `updatedAt`, `isHidden` and `notifications` fields of a conversation document.
 * Used when a message has been sent, or when a conversation's data has been mutated.
 * @param currentUser - `User`
 * @param selectedConversation - `ProcessedConversation`
 * @param newMessageDate - `FirestoreTimestamp`
 * @param expiresAt - `FirebaseTimeStamp`
 * @param isNewConversationThread - `boolean`
 */
export const updateConversation = async (
  currentUser: User,
  selectedConversation: ProcessedConversation,
  newMessageDate: FirestoreTimestamp,
  expiresAt: FirestoreTimestamp,
  messageExpiryValue: number,
  isNewConversationThread?: boolean
) => {
  const updatedIsHidden: isHiddenField = {};
  const updatedNotifications: NotificationsField = {};
  selectedConversation.recipients.forEach((recipient) => {
    // update `isHidden` field for conversation
    if (selectedConversation.isHidden[recipient.id]) {
      updatedIsHidden[recipient.id] = {
        date: selectedConversation.isHidden[recipient.id].date,
        now: false,
      };
    }
    if (recipient.id === currentUser.id) {
      updatedNotifications[currentUser.id] = 0;
    } else {
      updatedNotifications[recipient.id] = incrementFieldValue();
    }
  });

  const updatedFields: Partial<FirestoreDocumentData> = {
    updatedAt: newMessageDate,
    isHidden: updatedIsHidden,
    notifications: updatedNotifications,
  };

  if (expiresAt > selectedConversation.expiresAt || isNewConversationThread) {
    updatedFields.expiresAt = expiresAt;
    updatedFields.longestMessageExpiryValue = messageExpiryValue;
  }

  await updateFirestoreDocument(
    AvailableCollections.CONVERSATIONS,
    selectedConversation.id,
    updatedFields
  );
};

/**
 * A function utilized to encrypt a message, create and add the encrypted message document to firebase
 * and, if applicable, invite recipients to the conversation via an emailed magic link
 * @param selectedConversation - `ProcessedConversation`
 * @param currentUser - `User`
 * @param isNewConversationThread - `boolean`
 * @param messagePayload - `{ input: string; fileAttachment?: FileAttachment; }`
 */
export const createMessage = async (
  selectedConversation: ProcessedConversation,
  currentUser: User,
  isNewConversationThread: boolean,
  messagePayload: {
    input: string;
    fileAttachment?: FileAttachment;
  },
  dispatch: Dispatch,
  handleScrollToBottom: () => void
) => {
  try {
    const messageExpiryTime =
      selectedConversation.messageExpiryTimeList[
        selectedConversation.messageExpiryTimeList.length - 1
      ].value;

    const newMessageDocumentId = await createDocumentId(
      AvailableCollections.MESSAGES
    );

    const newMessageDate = setFirebaseTimestamp();

    // Create a newMessageDocument with state LOADING
    let newMessageDocument: EncryptedMessageDocument = {
      date: newMessageDate,
      id: newMessageDocumentId,
      locatorToken: '',
      payload: '',
      senderId: currentUser.id,
      state: MessageState.LOADING,
      conversationId: selectedConversation.id,
    };

    handleScrollToBottom();
    const recipientEmails = selectedConversation.recipients.map((recipient) =>
      recipient.settings && recipient.settings.isAliasUser
        ? recipient.email + '@alias.local'
        : recipient.email
    );

    const detectedUrls = detectURLs(messagePayload.input);
    let urlAttachments: URLAttachment[] = [];

    if (detectedUrls) {
      urlAttachments = await fetchURLsMetadata(detectedUrls);
    }

    const decryptedMessagePayload: DecryptedMessagePayload = {
      fileAttachment:
        messagePayload.fileAttachment || baseMessageDocument.fileAttachment,
      text: messagePayload.input,
      expirationHours: messageExpiryTime,
      urlAttachments,
    };

    // this will allow the user to instantly see what message they've sent in thread before the
    // message is actually uploaded
    const readableProcessedMessage = {
      ...newMessageDocument,
      status: 200,
      ...decryptedMessagePayload,
      sender: {
        avatar: currentUser[UserModelFields.AVATAR],
        id: currentUser[UserModelFields.ID],
        name: currentUser[UserModelFields.NAME],
      },
      state: MessageState.READABLE,
    };

    // TODO(worstestes - 6.14.22): temporary use until file attachment instant feedback update
    if (!messagePayload.fileAttachment) {
      dispatch(setProcessedMessages([readableProcessedMessage]));
    } else {
      dispatch(setMessageLoaders(newMessageDocument));
    }

    const decryptedMessagePayloadToString: string = JSON.stringify(
      decryptedMessagePayload
    );

    const encryptedMessagePayload = await encryptMessage(
      decryptedMessagePayloadToString,
      recipientEmails,
      selectedConversation.id,
      newMessageDocument.id,
      messageExpiryTime
    );

    if (!messagePayload.fileAttachment) {
      dispatch(
        setProcessedMessages([
          {
            ...readableProcessedMessage,
            locatorToken: encryptedMessagePayload.locatorToken,
            payload: encryptedMessagePayload.encryptedText,
          },
        ])
      );
    }

    if (isNewConversationThread) {
      const invitedRecipientEmails = recipientEmails
        .filter((email) => currentUser.email !== email)
        .filter((email) => !email.includes('@alias.local'));

      await sendMagicLink(
        invitedRecipientEmails,
        selectedConversation.id,
        currentUser
      );
    }

    newMessageDocument = {
      ...newMessageDocument,
      locatorToken: encryptedMessagePayload.locatorToken,
      payload: encryptedMessagePayload.encryptedText,
      senderId: currentUser.id,
      state: MessageState.ACTIVE,
    };

    await createFirestoreSubCollectionDocument(
      AvailableCollections.CONVERSATIONS,
      selectedConversation.id,
      AvailableCollections.MESSAGES,
      newMessageDocument.id,
      newMessageDocument
    );

    await updateConversation(
      currentUser,
      selectedConversation,
      newMessageDate,
      setExpiresAtTimestamp(messageExpiryTime),
      messageExpiryTime,
      isNewConversationThread
    );
  } catch (error) {
    console.error(error);
  }
};

type ValidationErrorStatusCode = 400 | 404 | 410 | 411 | 500;

const ValidationErrorMessage: {
  [index in ValidationErrorStatusCode]: string;
} = {
  400: 'There was an issue decrypting this message',
  404: 'There was an issue decrypting this message',
  410: 'This message has been deleted',
  411: 'This message has expired',
  500: 'There was an issue communicating with the server',
};

const generateErrorMessage = (error: AxiosError) => {
  const status = error.response?.status as ValidationErrorStatusCode;

  const errorMessage =
    ValidationErrorMessage[status] || ValidationErrorMessage[400];

  return {
    status: status || 400,
    text: errorMessage,
    fileAttachment: baseMessageDocument.fileAttachment,
  };
};

/**
 * A function utilized to decrypt a file attachment of a message
 * @param fileAttachment - `FileAttachment`
 * @returns - { name: string, type: string; url: string; size: number; }
 */
const decryptFileAttachment = async (fileAttachment: FileAttachment) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let fileDecryptionError: any = {};

  try {
    const decryptedFileBlob = await axios.get(fileAttachment.url as string, {
      responseType: 'blob',
    });

    const blobToFile = new File([decryptedFileBlob.data], fileAttachment.name);

    const decryptedFile = (await decryptFile(blobToFile)) as File;

    const decryptedFileUrl =
      decryptedFileBlob && URL.createObjectURL(decryptedFile);

    return {
      fileAttachment: {
        name: decryptedFile.name,
        type: fileAttachment.type,
        url: decryptedFileUrl,
        size: decryptedFileBlob && decryptedFile.size,
      },
    };
  } catch (error: unknown) {
    fileDecryptionError = generateErrorMessage(error as AxiosError);
  }

  // if there was a file decryption error we pass along the generated error message and also ensure
  // the file name is wiped from the user's view since it was not decrypted succesfully
  if (fileDecryptionError.status) {
    return {
      ...fileDecryptionError,
      fileAttachment: {
        name: '',
      },
    };
  }
};

/**
 * A function utilized to process and format a `MessageDocument` from Firestore.
 * This process includes fetching sender data amd decrypting text or files of the `MessageDocument`
 * @param message - a `MessageDocument` from Firestore
 * @returns `ProcessedMessage`
 */
export const processMessage = async (
  message: EncryptedMessageDocument
): Promise<ProcessedMessage> => {
  const decryptedPayload: DecryptedMessagePayload =
    await decryptMessage(message);
  const decryptedFileAttachment = decryptedPayload.fileAttachment.url
    ? await decryptFileAttachment(decryptedPayload.fileAttachment)
    : baseMessageDocument.fileAttachment;

  const senderUserData: User = await fetchUser(message.senderId);

  const senderName = senderUserData.name
    ? senderUserData.name
    : senderUserData.email;

  // TODO: find a more elegant way to handle message state
  const getMessageState = () => {
    switch (message.state) {
      case MessageState.DELETED:
        return MessageState.DELETED;
      case MessageState.READABLE:
        return MessageState.READABLE;
      case MessageState.EXPIRED:
        return MessageState.EXPIRED;
      default:
        return MessageState.READABLE;
    }
  };

  const state = getMessageState();

  message = {
    ...message,
    state,
  };

  return {
    ...message,
    status: 200,
    ...decryptedPayload,
    ...decryptedFileAttachment,
    date: message.date,
    sender: {
      avatar: senderUserData.avatar,
      id: senderUserData.id,
      name: senderName,
    },
  };
};

const sendInviteEmail = async (
  recipientEmail: string,
  conversationId: string
) => {
  const body = {
    email: recipientEmail,
    subject: 'Secure Chat Invitation',
    template: 'invite',
    params: {
      conversationId,
    },
  };
  await AuthenticationController.requestLoginLink(body);
};

const sendInviteTextMessage = async (
  currentUser: User,
  recipientPhoneNumber: string,
  conversationId: string
) => {
  let textPrefix;
  const baseText =
    'has invited you to XQ Secure Chat. Click $link to join them. To opt out of receiving invitations, reply STOP.';

  // Restricted guests shouldn't send invitation emails
  if (currentUser.settings.isAliasUser) {
    textPrefix = 'A Guest ';
  } else {
    textPrefix = `${currentUser.email} `;
  }

  await authorizeUser({
    user: recipientPhoneNumber,
    text: textPrefix + baseText,
    codetype: 'sms',
    target: `${env.REACT_APP_BASE_HOST}/login/sms?conversationId=${conversationId}`,
  });
};

/**
 * A function utilized to send an invite email link to recipients of a given conversation.
 * If the environment is set to `dev`, this function will not run.
 * @param inviteRecipientList - a list of recipient emails and/or phone numbers to invite
 * @param conversationId - the id of the given conversation to invite recipients to
 * @returns void
 */
export const sendMagicLink = async (
  inviteRecipientList: string[],
  conversationId: string,
  currentUser: User
) => {
  inviteRecipientList.forEach(async (recipient: string) => {
    // if recipient is using email
    // TODO(worstestes - 9.19.22): let's utilize email regex for this
    if (recipient.includes('@')) {
      return sendInviteEmail(recipient, conversationId);
    }

    return sendInviteTextMessage(currentUser, recipient, conversationId);
  });
};
