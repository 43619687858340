/**
 * A utility function used to determine whether an JWT is expired or not.
 * @note - this function does not verify the JWT signature is valid and optional expiration, audience, or issuer are valid.
 * @param token - a string value representing the JWT
 * @returns boolean
 */
const verifyJWTExpiration = (token: string): boolean => {
  const expirationDate = JSON.parse(atob(token.split(".")[1])).exp * 1000;
  return Date.now() >= expirationDate;
};

export default verifyJWTExpiration;
