import { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import AuthenticationController from 'auth/AuthenticationController';
import { useToast } from '@chakra-ui/react';
import routes from 'auth/routes';
import { Status } from '../types';

export function useLoginWithInvitedGuestLink() {
  const location = useLocation();
  const history = useHistory();
  const toast = useToast();
  const qs = useRef(new URLSearchParams(location.search.substr(1)));
  const [status, setStatus] = useState<Status>(Status.Initial);
  const conversationId = qs.current.get('conversationId') ?? '';

  useEffect(() => {
    const run = async () => {
      if (status === Status.Invalid) {
        toast({
          description:
            'The link you used has expired please login with your email or phone number to continue the conversation.',
          status: 'error',
          position: 'bottom-right',
        });
        return history.push(routes.login);
      }

      setStatus(Status.Checking);
      try {
        await AuthenticationController.verifyGuestAccount(
          conversationId,
          setStatus
        );
      } catch {
        setStatus(Status.Invalid);
      }
    };
    run();
  }, [conversationId, history, status, toast]);

  return {
    conversationId,
    status,
  };
}
