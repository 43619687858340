import React from 'react';
import { Marker } from '../../types';
import TraceRoute from '../TraceRoute';

interface TraceRoutesProps {
  destinationPoints: Marker[];
  originPoint: Marker | null;
}

const TraceRoutes: React.FC<TraceRoutesProps> = ({
  destinationPoints,
  originPoint,
}) => {
  if (!originPoint) {
    return null;
  }

  const Lines = (destinationPoints || []).map((point: Marker) => (
    <TraceRoute
      key={point.ip}
      destinationPoint={point}
      originPoint={originPoint}
    />
  ));

  return Lines;
};

export default TraceRoutes;
