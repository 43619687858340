import { createSlice } from '@reduxjs/toolkit';
import getInitialMessageLimit from 'dashboard/features/Thread/utils/getInitialMessageLimit';
import {
  EncryptedMessageDocument,
  ProcessedMessage,
} from 'dashboard/models/Message';

export interface FileResult {
  name: string;
  file: File;
  canView: boolean;
  preview: string;
}

interface messagesSliceState {
  processedMessages: ProcessedMessage[];
  fileAttachments: FileResult[];
  latestMessageId: string | null;
  messageInput: string;
  isSendingMessage: boolean;
  isToolTipVisible: boolean;
  messageLoaders: EncryptedMessageDocument[];
  fileUploadBytesTransferred: number;
  fileUploadTotalBytes: number;
  isLoading: boolean;
  currentMessageThreadLimit: number;
  scrollBarWidth: number;
  newMessageViewed: boolean;
  shouldDisplayScrollToBottom: boolean;
}

const initialState = () =>
  ({
    processedMessages: [],
    fileAttachments: [],
    messageInput: '',
    latestMessageId: null,
    isSendingMessage: false,
    isToolTipVisible: false,
    messageLoaders: [],
    fileUploadBytesTransferred: 0,
    fileUploadTotalBytes: 0,
    isLoading: true,
    scrollBarWidth: 6,
    currentMessageThreadLimit: getInitialMessageLimit(-15),
    newMessageViewed: true,
    shouldDisplayScrollToBottom: false,
  }) as messagesSliceState;

const arrayUniqueByKey = (
  arr: ProcessedMessage[] | EncryptedMessageDocument[]
) => [
  ...new Map(
    // @ts-ignore unsure why this is breaking, code seems correct
    arr.map((item: ProcessedMessage | EncryptedMessageDocument) => [
      item.id,
      item,
    ])
  ).values(),
];

const messagesSlice = createSlice({
  name: 'messages',
  initialState: initialState(),
  reducers: {
    setProcessedMessages(state, action) {
      const message = action.payload;
      state.processedMessages = arrayUniqueByKey([
        ...state.processedMessages,
        ...message,
      ]).sort((a, b) => a.date.seconds - b.date.seconds) as ProcessedMessage[];

      state.latestMessageId =
        state.processedMessages[state.processedMessages.length - 1].id;
    },
    setCurrentMessageThreadLimit(state, action) {
      const currentMessageThreadLimit = action.payload;
      state.currentMessageThreadLimit = currentMessageThreadLimit;
    },
    setIsLoading(state, action) {
      const isLoading = action.payload;
      state.isLoading = isLoading;
    },
    setFileAttachments(state, action) {
      const fileAttachments = action.payload;
      state.fileAttachments = fileAttachments;
    },
    setMessageInput(state, action) {
      const messageInput = action.payload;
      state.messageInput = messageInput;
    },
    setToolTipVisible(state, action) {
      const isToolTipVisible = action.payload;
      state.isToolTipVisible = isToolTipVisible;
    },
    setMessageLoaders(state, action) {
      const messageLoader = action.payload;
      state.messageLoaders = arrayUniqueByKey([
        ...state.messageLoaders,
        messageLoader,
      ]).sort(
        (a, b) => b.date.seconds - a.date.seconds
      ) as EncryptedMessageDocument[];
    },
    removeMessageLoader(state, action) {
      const messageLoaders = action.payload;
      state.messageLoaders = [...state.messageLoaders]
        .filter((msgLoader) => !messageLoaders.includes(msgLoader.id))
        .sort((a, b) => b.date.seconds - a.date.seconds);
    },
    setFileUploadTotalBytes(state, action) {
      const fileUploadTotalBytes = action.payload;
      state.fileUploadTotalBytes = fileUploadTotalBytes;
    },
    setFileUploadBytesTransferred(state, action) {
      const fileUploadBytesTransferred = action.payload;
      state.fileUploadBytesTransferred = fileUploadBytesTransferred;
    },
    removeFileAttachment(state, action) {
      state.fileAttachments = state.fileAttachments.filter(
        (fileAttachment) => fileAttachment.file !== action.payload
      );
    },
    setScrollBarWidth(state, action) {
      const scrollBarWidth = action.payload;
      state.scrollBarWidth = scrollBarWidth;
    },
    setNewMessageViewed(state, action) {
      const newMessageViewed = action.payload;
      state.newMessageViewed = newMessageViewed;
    },
    setShouldDisplayScrollToBottom(state, action) {
      const shouldDisplayScrollToBottom = action.payload;
      state.shouldDisplayScrollToBottom = shouldDisplayScrollToBottom;
    },

    resetMessageState: () => initialState(),
  },
});

export const {
  setProcessedMessages,
  resetMessageState,
  setFileAttachments,
  setMessageInput,
  removeFileAttachment,
  // setIsSendingMessage,
  setToolTipVisible,
  setMessageLoaders,
  setFileUploadBytesTransferred,
  setFileUploadTotalBytes,
  removeMessageLoader,
  // removeMessage,
  setIsLoading,
  setCurrentMessageThreadLimit,
  setScrollBarWidth,
  setNewMessageViewed,
  setShouldDisplayScrollToBottom,
} = messagesSlice.actions;

export default messagesSlice.reducer;
