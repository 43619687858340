import React, { useState } from 'react';
import { SimpleGrid } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { PostLoginReturnState } from 'auth/ProtectedRoute';
import {
  CenteredLayoutContent,
  CenteredLayoutContentInner,
  CenteredLayoutHeading,
} from 'shared/components/CenteredLayout';
import FormErrorAlert from 'shared/components/FormErrorAlert';
import { useGoogleLogin } from 'shared/components/sso/useGoogleLogin';

import { StyleSheet } from 'dashboard/scss/StyleSheet';

import colors from 'theme/foundations/colors';
// import ToggleButton from '../../../shared/components/ToggleButton';
import Phone from './Phone';
import EmailLogin from './Email/EmailLogin';

enum Toggle {
  email,
  phone,
}

function LoginForm({ isMobile }: { isMobile: boolean }) {
  const { state } = useLocation<PostLoginReturnState | undefined>();
  // const [toggle, setToggle] = useState(Toggle.email);
  const [toggle] = useState(Toggle.email);

  const { requestGoogleLogin, error: googleError } = useGoogleLogin(
    state?.returnTo
  );

  /* const handleToggle = (val: boolean) => {
    if (val) {
      return setToggle(Toggle.phone);
    }
    return setToggle(Toggle.email);
  }; */

  return (
    <CenteredLayoutContent
      noValidate
      alignItems="unset"
      // mb={0}
      mt={0}
      height="407px"
    >
      <CenteredLayoutHeading
        style={styles.formHeader}
        fontSize={isMobile ? '15px' : '24px'}
        mb={isMobile ? '18px' : '32px'}
      >
        Sign In
      </CenteredLayoutHeading>
      <CenteredLayoutContentInner>
        <FormErrorAlert error={googleError} />
        <SimpleGrid spacing={isMobile ? 2 : 4}>
          {/*
          <ToggleButton handleToggle={handleToggle} isMobile={isMobile} />
          */}
          {toggle === Toggle.email && (
            <EmailLogin
              isMobile={isMobile}
              requestGoogleLogin={requestGoogleLogin}
              // form={form}
            />
          )}
          {toggle === Toggle.phone && <Phone isMobile={isMobile} />}
        </SimpleGrid>
      </CenteredLayoutContentInner>
    </CenteredLayoutContent>
  );
}

const styles = StyleSheet.create({
  formHeader: {
    fontFamily: 'proxima-nova',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '22px',
    textAlign: 'center',
    letterSpacing: '0.12em',
    color: colors.gray[700],
    textTransform: 'uppercase',
  },
});

export default LoginForm;
