/**
 * This function takes an array of users and alphabetically sorts them.
 */

import { User } from 'dashboard/models/User';

const getRecipientList = (recipients: User[], currentUserId: string) => {
  return recipients
    .filter((recipient) => recipient.avatar && recipient.id !== currentUserId)
    .sort((a, b) => {
      if (a.name) {
        return a.name.localeCompare(b.name || b.email);
      }
      return a.email.localeCompare(b.name || b.email);
    });
};

export default getRecipientList;
