/* eslint-disable react/no-array-index-key */
// Links here use the array index, which is ok since it never changes on runtime
import React, { Fragment } from 'react';
import {
  Box,
  Link as ChakraLink,
  Icon as ChakraIcon,
  Collapse,
} from '@chakra-ui/react';
import { HiChevronRight } from 'react-icons/hi';
import {
  useRouteMatch,
  useLocation,
  Link as RouterLink,
} from 'react-router-dom';
import r from 'routes';

import NavigationLink, { NavigationLinkProps } from './NavigationLink';
import CollapsableGroup from './CollapsableGroup';
import './styles.scss';

export type LinkOptions = {
  external?: boolean;
  target?: '_blank';
};

export type SubRouteDefinition = [
  route: string,
  title: string,
  options?: LinkOptions,
];

export type GroupedSubrouteDefinition = {
  heading?: string;
  routes: SubRouteDefinition[];
};

type SubNavigationChevronProps = {
  isActive: boolean;
  show?: boolean;
};

// eslint-disable-next-line react/function-component-definition
const SubNavigationChevron: React.FC<SubNavigationChevronProps> = ({
  isActive,
  show = false,
}) => {
  if (!show) {
    return null;
  }

  return (
    <ChakraIcon
      as={HiChevronRight}
      transform={isActive ? 'rotate(90deg)' : undefined}
      transition="transform 0.2s linear"
      boxSize={6}
      color="blue.500"
      display="inherit"
      ml="auto"
    />
  );
};

interface LinkOpts {
  children: React.ReactNode;
  to: string;
  key: string;
  options?: LinkOptions;
}

// eslint-disable-next-line react/function-component-definition
const Link: React.FC<LinkOpts> = ({ children, options, to, ...props }) => {
  if (options?.external) {
    return (
      <ChakraLink
        href={to}
        {...props}
        variant="subNavigation"
        pr={4}
        target={options?.target}
        display="flex"
      >
        {children}
      </ChakraLink>
    );
  }
  return (
    <ChakraLink
      as={NavigationLink}
      variant="subNavigation"
      pr={4}
      display="flex"
      to={to}
      {...props}
    >
      {children}
    </ChakraLink>
  );
};

type SubNavigationMenuProps = {
  show: boolean;
  subRoutes?: Array<SubRouteDefinition>;
  groupedSubRoutes?: Array<GroupedSubrouteDefinition>;
};

// eslint-disable-next-line react/function-component-definition
const SubNavigationMenu: React.FC<SubNavigationMenuProps> = ({
  show,
  subRoutes,
  groupedSubRoutes,
}) => {
  return (
    <Collapse in={show}>
      <Box py={3}>
        {subRoutes?.map(([route, t, options], idx) => {
          return (
            <Link to={route} key={`${route}-${idx}`} options={options}>
              {t}
            </Link>
          );
        })}
        {groupedSubRoutes?.map(({ heading, routes }, i) => {
          if (!heading) {
            return (
              <Fragment key={i}>
                <Box fontWeight="bold" fontSize="sm" pt={4} pl={12}>
                  {heading}
                </Box>
                {routes?.map(([route, t, options]) => {
                  return (
                    <Link to={route} key={`${route}-${i}`} options={options}>
                      {t}
                    </Link>
                  );
                })}
              </Fragment>
            );
          }
          return (
            <Fragment key={heading}>
              <CollapsableGroup heading={heading}>
                <Box mt={3}>
                  {routes?.map(([route, t, options], idx) => (
                    <Link to={route} key={`${route}-${idx}`} options={options}>
                      {t}
                    </Link>
                  ))}
                </Box>
              </CollapsableGroup>
            </Fragment>
          );
        })}
      </Box>
    </Collapse>
  );
};

type LinkGroupProps = Omit<NavigationLinkProps, 'to'> & {
  title?: string;
  StaticIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  ClickedIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  to?: string;
  href?: string;
  subRoutes?: Array<SubRouteDefinition>;
  groupedSubRoutes?: Array<GroupedSubrouteDefinition>;
  isExternal?: boolean;
  isStatic?: boolean;
  onClick?: () => void;
};

// eslint-disable-next-line react/function-component-definition
const LinkGroup: React.FC<LinkGroupProps> = ({
  StaticIcon,
  ClickedIcon,
  href,
  title,
  subRoutes,
  groupedSubRoutes,
  isExternal,
  isStatic,

  onClick,
  ...props
}) => {
  const { to, exact, isActive: isCustomActive } = props;
  const routeMatch = useRouteMatch();
  const location = useLocation();

  const isCustomActiveMatch = isCustomActive
    ? isCustomActive(routeMatch, location)
    : false;
  const isActive =
    !!useRouteMatch({
      path: to,
    }) || isCustomActiveMatch;

  // We have to different active states. One for when this group is active due to the
  // user being on a child route, and another for when the user is exactly on this route.
  const isExactlyActive = !!useRouteMatch({
    path: to,
    exact: true,
  });

  const hasSubRoutes =
    !!(subRoutes && subRoutes.length) ||
    !!(groupedSubRoutes && groupedSubRoutes.length);

  // We want to use the exact variant when ever there is an exact match or
  // if there is a partial match, but no sub routes. That means there in some
  // child navigation going on that is not linked to a navigation item.
  const useExactVariant = isExactlyActive || (!exact && !hasSubRoutes);
  return (
    <div>
      <ChakraLink
        href={href}
        variant={useExactVariant ? 'sidebarExact' : 'sidebar'}
        // {...props}
        sx={{
          ':hover .chakra-icon--navitem': {
            color: 'blue.500',
          },
        }}
        isExternal={isExternal}
        as={to ? RouterLink : ChakraLink}
        onClick={onClick}
        className="link-group"
      >
        <div>
          {isExactlyActive && !isStatic ? (
            <ClickedIcon
              className={`link-group-icon clicked ${
                to === r.feedback ? 'feedback-focused' : ''
              }`}
            />
          ) : (
            <StaticIcon
              className={`link-group-icon static ${
                title === 'Help' || title === 'Workspace' ? 'stroke' : ''
              } ${to === r.feedback ? 'feedback-focused' : ''}  `}
            />
          )}

          {title && <p className="link-group-text">{title}</p>}
        </div>

        <SubNavigationChevron show={hasSubRoutes} isActive={isActive} />
      </ChakraLink>
      <SubNavigationMenu
        show={hasSubRoutes && isActive}
        subRoutes={subRoutes ?? []}
        groupedSubRoutes={groupedSubRoutes ?? []}
      />
    </div>
  );
};

export default LinkGroup;
