import React, {
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  CompositeDecorator,
  ContentBlock,
  ContentState,
  EditorState,
  Modifier,
  RichUtils,
} from 'draft-js';
import {
  Flex,
  IconButton,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';

import fileLogo from 'assets/svg/icon-lock-file-inactive.svg';
import sendButtonActive from 'assets/svg/send-solid-blue.svg';
import sendButtonDisabled from 'assets/svg/send-solid-grey.svg';
import { StackedInputField } from 'shared/components/form';
import { FormProvider } from 'react-hook-form';
import SpinnerButton from 'shared/components/SpinnerButton';
import { FileResult } from '../../../..';
import { inlineStyles, blockStyles, entityStyles } from './constants';
import ToolbarIcon from './components/ToolbarIcon';
import useLinkForm from './useLinkFormHandler';
import moveSelectionToEnd from '../utils/moveSelectionToEnd';
import { getSelectedText } from '../utils/getSelectedText';
import {
  clearInlineStyles,
  clearInlineStylesBlock,
} from '../utils/clearInlineStyles';
import { getCurrentBlockType } from '../utils/getCurrentBlockType';

export interface ToolbarProps {
  editorState: EditorState;
  setEditorState: React.Dispatch<SetStateAction<EditorState>>;
  onSendMessage: () => void;
  onAddFileAttachment: () => void;
  messageInput: string;
  fileAttachments: FileResult[];
  hasFocus: boolean;
  codeSnippetEnabled: boolean;
  setCodeSnippetEnabled: (value: boolean) => void;
}

const findLinkEntities = (
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState
) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'LINK'
    );
  }, callback);
};

function Link({
  children,
  contentState,
  entityKey,
}: {
  contentState: ContentState;
  entityKey: string;
  children: ReactNode;
}) {
  const { url } = contentState.getEntity(entityKey).getData();
  return <a href={url}>{children}</a>;
}

export const strategyDecorator = new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: Link,
  },
]);

const Toolbar: React.FC<ToolbarProps> = ({
  editorState,
  messageInput,
  fileAttachments,
  hasFocus,
  codeSnippetEnabled,
  setCodeSnippetEnabled,
  setEditorState,
  onSendMessage,
  onAddFileAttachment,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [codeBlockEnabled, setCodeBlockEnabled] = useState(false);

  const { form, onSubmit } = useLinkForm(
    (url, text) => {
      setShowModal(false);

      let link = url;

      // prepend protocol to url if not included
      if (!url.includes('http://')) {
        if (!url.includes('https://')) {
          link = `http://${url}`;
        }
      }

      const currentContent = editorState.getCurrentContent();

      // Create the entity
      currentContent.createEntity('LINK', 'MUTABLE', {
        url: link,
        target: '_blank',
      });

      const entityKey = currentContent.getLastCreatedEntityKey();

      const selection = editorState.getSelection();

      // Get state with link/text that was passed in to the callback
      const contentStateWithLink = Modifier.replaceText(
        currentContent,
        selection,
        text,
        editorState.getCurrentInlineStyle(),
        entityKey
      );

      const newEditorState = EditorState.createWithContent(
        contentStateWithLink,
        strategyDecorator
      );

      return setEditorState(
        moveSelectionToEnd(
          EditorState.forceSelection(
            newEditorState,
            newEditorState.getSelection()
          )
        )
      );
    },
    useMemo(() => {
      // Get the highlighted text within the editor to linkify
      return getSelectedText(editorState);
    }, [editorState])
  );

  const handleInlineStyle = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    type: string
  ) => {
    event.preventDefault();

    if (!hasFocus) return;

    let newEditorState = editorState;
    if (type === 'CODE') {
      newEditorState = clearInlineStyles(newEditorState, [
        'BOLD',
        'ITALIC',
        'UNDERLINE',
      ]);
      setCodeSnippetEnabled(!codeSnippetEnabled);
    }

    setEditorState(RichUtils.toggleInlineStyle(newEditorState, type));
  };

  const handleBlockStyle = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    block: string
  ) => {
    event.preventDefault();

    if (!hasFocus) return;

    let newEditorState = editorState;

    if (block === 'code-block') {
      setCodeBlockEnabled(!codeBlockEnabled);

      newEditorState = clearInlineStylesBlock(editorState);
    }

    setEditorState(
      EditorState.forceSelection(
        RichUtils.toggleBlockType(newEditorState, block),
        newEditorState.getSelection()
      )
    );
  };

  const handleEntityStyle = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();

    if (!hasFocus) return;

    setShowModal(true);
  };

  const renderInlineStyleButton = (style: (typeof inlineStyles)[0]) => {
    const currentInlineStyle = editorState.getCurrentInlineStyle();

    return (
      <ToolbarIcon
        hasFocus={hasFocus}
        type={style.type}
        toolTip={style.toolTip}
        onMouseDown={handleInlineStyle}
        disabled={
          (style.type !== 'CODE' && codeSnippetEnabled) || codeBlockEnabled
        }
        icon={style.icon}
        isActive={currentInlineStyle.has(style.type)}
      />
    );
  };

  const renderBlockStyleButton = (block: (typeof blockStyles)[0]) => {
    const currentBlockType = RichUtils.getCurrentBlockType(editorState);

    return (
      <ToolbarIcon
        hasFocus={hasFocus}
        type={block.type}
        toolTip={block.toolTip}
        onMouseDown={handleBlockStyle}
        icon={block.icon}
        disabled={
          (block.type !== 'code-block' && codeSnippetEnabled) ||
          codeSnippetEnabled
        }
        isActive={currentBlockType === block.type}
      />
    );
  };

  const renderEntityStyleButton = (entity: (typeof entityStyles)[0]) => {
    const currentInlineStyle = editorState.getCurrentInlineStyle();

    return (
      <ToolbarIcon
        hasFocus={hasFocus}
        type={entity.type}
        toolTip={entity.toolTip}
        onMouseDown={handleEntityStyle}
        icon={entity.icon}
        disabled={codeBlockEnabled || codeSnippetEnabled}
        isActive={currentInlineStyle.has(entity.type)}
      />
    );
  };

  useEffect(() => {
    const currentInlineStyle = editorState.getCurrentInlineStyle();

    const currentBlockType = getCurrentBlockType(editorState);
    if (currentBlockType !== 'code-block') {
      setCodeBlockEnabled(false);
    }

    if (currentBlockType === 'code-block') {
      setCodeBlockEnabled(true);
    }

    if (!currentInlineStyle.includes('CODE')) {
      setCodeSnippetEnabled(false);
    }
  }, [editorState, setEditorState, setCodeSnippetEnabled]);

  const contentExists =
    editorState.getCurrentContent().getPlainText().length > 0;

  // if there is no message content OR there is no file attachment is present, disable the send button.
  const isSendButtonDisabled = !!(
    (messageInput.length && contentExists) ||
    fileAttachments.length
  );

  return (
    <div id="toolbar">
      <div>
        {inlineStyles.map((type) => {
          return renderInlineStyleButton(type);
        })}
        {blockStyles.map((block) => {
          return renderBlockStyleButton(block);
        })}
        {entityStyles.map((entity) => {
          return renderEntityStyleButton(entity);
        })}
      </div>
      <div>
        <IconButton
          onClick={onAddFileAttachment}
          aria-label="back-button"
          size="sm"
          colorScheme="transparent"
        >
          <Image objectFit="fill" alt="add-file-attachment" src={fileLogo} />
        </IconButton>
        <IconButton
          onClick={() => {
            if (!contentExists) {
              if (fileAttachments.length) {
                return onSendMessage();
              }
              return;
            }

            return onSendMessage();
          }}
          aria-label="send-button"
          size="sm"
          colorScheme="transparent"
        >
          {isSendButtonDisabled ? (
            <Image w="20px" alt="active-send-button" src={sendButtonActive} />
          ) : (
            <Image
              w="20px"
              alt="inactive-send-button"
              src={sendButtonDisabled}
            />
          )}
        </IconButton>
      </div>

      {showModal && (
        <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
          <ModalOverlay />
          <ModalContent
            h="fit-content"
            maxW="809px"
            w="100%"
            borderRadius="10px"
            background="#FBFBFB"
            padding="15px"
          >
            <FormProvider {...form}>
              <form onSubmit={onSubmit}>
                <StackedInputField
                  id="url"
                  name="url"
                  type="text"
                  label="Link"
                  autoCorrect="off"
                  spellCheck={false}
                  autoComplete="off"
                  marginBottom="10px"
                />
                <StackedInputField
                  id="text"
                  name="text"
                  type="text"
                  label="Text"
                  autoCorrect="off"
                  spellCheck={false}
                  autoComplete="off"
                />
                <Flex justifyContent="flex-end" marginTop="10px">
                  <SpinnerButton
                    type="button"
                    onClick={() => setShowModal(false)}
                    variant="outline"
                    marginRight="10px"
                  >
                    Close
                  </SpinnerButton>
                  <SpinnerButton type="submit">Save</SpinnerButton>
                </Flex>
              </form>
            </FormProvider>
          </ModalContent>
        </Modal>
      )}
    </div>
  );
};

export default Toolbar;
