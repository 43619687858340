import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store';
import {
  setFileAttachments,
  setFileUploadBytesTransferred,
  setFileUploadTotalBytes,
  setMessageInput,
  setToolTipVisible,
} from 'store/reducers/messages';
import { createMessage } from 'dashboard/services/chat';
import { ProcessedConversation } from 'dashboard/models/Conversation';
import { encryptAndUploadFiles } from 'dashboard/services/storage';

/**
 * A function utilized to send a chat message and store the message and its content into the DB
 * @param pendingMessageInput - string; the message to be sent.
 * @returns
 */

export const onSendMessage = createAsyncThunk<
  null | void,
  {
    message: string;
    inputMessageRef: React.MutableRefObject<HTMLDivElement>;
    handleScrollToBottom: () => void;
  }
>(
  'conversations/create',
  async (
    { message, inputMessageRef, handleScrollToBottom },
    { dispatch, getState }
  ) => {
    const {
      conversations: { selectedConversation },
      user: { currentUser },
      messages: {
        processedMessages,
        fileAttachments,
        messageInput,
        fileUploadTotalBytes,
        fileUploadBytesTransferred,
      },
    } = getState() as RootState;

    const isNewConversationThread = processedMessages.length <= 0;

    if (!currentUser) {
      return null;
    }

    const pendingMessageInput = message;
    dispatch(setMessageInput(''));

    // inform the user via tooltip that there must be a message entered prior to attempting to send a message
    if (!pendingMessageInput.trim() && !fileAttachments.length) {
      dispatch(setToolTipVisible(true));
      setTimeout(() => {
        dispatch(setToolTipVisible(false));
      }, 1000);
      return;
    }

    const conversation = selectedConversation as ProcessedConversation;
    if (messageInput) {
      await createMessage(
        conversation,
        currentUser,
        isNewConversationThread,
        {
          input: pendingMessageInput,
        },
        dispatch,
        handleScrollToBottom
      );
    }

    if (fileAttachments.length) {
      const recipientEmails = selectedConversation.recipients.map(
        (recipient) =>
          recipient.settings.isAliasUser
            ? recipient.email + '@alias.local'
            : recipient.email
      );

      fileAttachments.forEach((fileAttachment) => {
        dispatch(
          setFileUploadTotalBytes(
            fileUploadTotalBytes + fileAttachment.file.size
          )
        );
      });

      const uploadedFileAttachments = await encryptAndUploadFiles(
        fileAttachments,
        recipientEmails,
        fileUploadBytesTransferred,
        dispatch,
        conversation
      );

      uploadedFileAttachments.map(async (uploadedFileAttachment, idx) => {
        createMessage(
          conversation,
          currentUser,
          isNewConversationThread,
          {
            input: '',
            fileAttachment: await uploadedFileAttachment,
          },
          dispatch,
          handleScrollToBottom
        ).then(() => {
          if (idx === uploadedFileAttachments.length - 1) {
            dispatch(setFileUploadBytesTransferred(0));
            dispatch(setFileUploadTotalBytes(0));
          }
        });
      });
    }

    dispatch(setFileAttachments([]));
    inputMessageRef.current.focus();
    dispatch(setToolTipVisible(false));
    inputMessageRef.current.focus();
    handleScrollToBottom();
  }
);
