import React from 'react';

import { FileResult } from 'dashboard/features/Thread';
import { AddIcon } from '@chakra-ui/icons';
import { Progress } from '@chakra-ui/progress';
import { StyleSheet } from 'dashboard/scss/StyleSheet';

import nonViewablefileAttachment from 'assets/svg/icon-lock-fil-large.svg';
import videoAttachment from 'assets/svg/icon-video-attachment.svg';

interface FileAttachmentListProps {
  fileAttachments: FileResult[];
  fileUploadProgress: number;
  onAddFileAttachment: () => void;
  onRemoveFileAttachment: (fileAttachment: FileResult) => void;
}

const FileAttachmentList: React.FC<FileAttachmentListProps> = ({
  fileAttachments,
  fileUploadProgress,
  onAddFileAttachment,
  onRemoveFileAttachment,
}) => {
  const renderThumbnail = (fileAttachment: FileResult) => {
    let fileAttachmentPlaceholder = nonViewablefileAttachment;
    if (fileAttachment.file.type.includes('video')) {
      fileAttachmentPlaceholder = videoAttachment;
    }
    if (fileAttachment.file.type.includes('image')) {
      fileAttachmentPlaceholder = fileAttachment.preview;
    }

    return (
      <img
        className="imgact"
        src={fileAttachmentPlaceholder}
        alt=""
        style={styles.attachmentListItem}
      />
    );
  };
  return (
    <div style={styles.mainContainer}>
      {fileUploadProgress > 0 && (
        <Progress
          colorScheme="blue"
          style={styles.fileUploadProgress}
          value={fileUploadProgress}
        />
      )}
      {fileAttachments.length > 0 && (
        <div style={styles.fileAttachmentList}>
          {fileAttachments.map((fileAttachment) => (
            <div
              className="img-wrap"
              style={styles.attachmentListItemContainer}
            >
              <span
                onClick={() => onRemoveFileAttachment(fileAttachment)}
                className="close"
                role="button"
                tabIndex={0}
                onKeyDown={() => onRemoveFileAttachment(fileAttachment)}
              >
                &times;
              </span>
              {renderThumbnail(fileAttachment)}
            </div>
          ))}
          {/* eslint-disable-next-line */}
          <div
            style={styles.addAttachmentItem}
            onClick={onAddFileAttachment}
            onKeyDown={onAddFileAttachment}
            role="button"
            tabIndex={0}
          >
            <AddIcon w={7} h={7} color="#d3d3d3" />
          </div>
        </div>
      )}
    </div>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    display: 'flex',
    zIndex: 100,
    width: '90%',
    overflowX: 'auto',
  },
  fileAttachmentList: {
    alignItems: 'center',
    borderRadius: 5,
    display: 'flex',
    flexWrap: 'nowrap',
    padding: 10,
  },
  fileUploadProgress: {
    backgroundColor: '#ebebeb',
    borderRadius: 10,
    width: '92%',
    marginBottom: 10,
  },
  attachmentListItemContainer: { margin: '0 2px 0 0', height: 100, width: 100 },
  attachmentListItem: {
    borderRadius: 4,
    border: '1px solid #ededed',
    width: '100%',
    height: '100%',
    position: 'relative',
    backgroundColor: '#ffffff',
  },
  addAttachmentItem: {
    backgroundColor: '#ffffff',
    alignItems: 'center',
    border: '2px solid #d3d3d3',
    borderRadius: 4,
    cursor: 'pointer',
    display: 'flex',
    height: 100,
    justifyContent: 'center',
    margin: '0 2px 0 2px',
    width: 100,
  },
});

export default FileAttachmentList;
