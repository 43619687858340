import {
  Authorize,
  AuthorizeAlias,
  CheckKeyExpiration,
  CodeValidator,
  CommunicationsEnum,
  DashboardLogin,
  Decrypt,
  Encrypt,
  EncryptionAlgorithm,
  FetchKey,
  FileDecrypt,
  FileEncrypt,
  GetBusinesses,
  GetContacts,
  GetCurrentUser,
  GetEventLogs,
  RevokeKeyAccess,
  ServerResponse,
  ValidateSession,
  VerifyAccount,
  XQSDK,
} from '@xqmsg/jssdk-core';
import { env } from 'env';
import { dashboardService } from 'backend/services';
import {
  baseMessageDocument,
  DecryptedMessagePayload,
  EncryptedMessageDocument,
  MessageState,
} from 'dashboard/models/Message';
import { EventLogItem } from 'event-log/types';
import { expireMessage } from './chat/expireMessage';

export const initializedXQSDK = new XQSDK(
  {
    XQ_API_KEY: env.REACT_APP_FRAMEWORK_API_KEY ?? '',
    DASHBOARD_API_KEY: env.REACT_APP_DASHBOARD_API_KEY ?? '',
  },
  {
    DASHBOARD_SERVER_URL: env.REACT_APP_DASHBOARD_HOST,
    KEY_SERVER_URL: env.REACT_APP_QUANTUM_HOST,
    SUBSCRIPTION_SERVER_URL: env.REACT_APP_SUBSCRIPTION_HOST,
    VALIDATION_SERVER_URL: env.REACT_APP_VALIDATION_HOST,
  }
);

/**
 * A function utilized to authorize the user to utilize XQ Dashboard services
 * @param pwd - the id token returns from OAuth
 * @returns an accessToken string
 */

export const dashboardLogin = async (pwd: string): Promise<string> => {
  const {
    payload: { dashboardAccessToken },
  } = await new DashboardLogin(initializedXQSDK).supplyAsync({
    pwd,
  });

  return dashboardAccessToken;
};

// TEMPORARY:
// TODO: @mitch: we need to update the sdk to allow for a GET dashboard login request that takes ?request='sub' as a query string
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const temporaryDashboardLogin = async (): Promise<any> =>
  dashboardService.get('/login/verify?request=sub');

/**
 * A function utilized to fetch event logs
 * @param maybePayload - payload used to filter logs
 * @returns an array of EventLogItems
 */
export const getEventLogs = async (data: string): Promise<EventLogItem[]> => {
  const {
    payload: { logs },
  } = await new GetEventLogs(initializedXQSDK).supplyAsync({
    [GetEventLogs.DATA]: data,
  });

  /*
  const res = await dashboardService.get<string>(`/log`, {
    params: {
      data,
    },
  });
   */

  return logs;
};

/**
 * A function utilized to retrieve and return an array of businesses available to an authorized user
 * @returns an array of businesses
 */
export const getBusinesses = async () => {
  const getBusinessesResponse = await new GetBusinesses(
    initializedXQSDK
  ).supplyAsync(null);

  const { businesses } = getBusinessesResponse.payload;

  return businesses;
};

/**
 * A function utilized to retrieve and return an array of contacts available to an authorized user
 * @returns an array of contacts
 */
export const getContacts = async () => {
  const getBusinessesResponse = await new GetContacts(
    initializedXQSDK
  ).supplyAsync(null);

  const { contacts } = getBusinessesResponse.payload;

  return contacts;
};

/**
 * A function utilized to retrieve and return the current user contact
 * @returns current user contact
 */
export const getCurrentUser = async () => {
  const getCurrentUserRequest = await new GetCurrentUser(
    initializedXQSDK
  ).supplyAsync(null);

  const { contact } = getCurrentUserRequest.payload;

  return contact;
};

/**
 * A function utilized to authorize the user to utilize XQ Dashboard services
 * @param accessToken - an existing pre-auth or full auth dashboard access token
 * @returns an accessToken string
 */
export const verifyAccount = async (accessToken: string): Promise<string> => {
  // utilized when validating an existing session, as well as during the magic link sign-in
  // process to validate the magic link's attached access token
  const response = await new VerifyAccount(initializedXQSDK).supplyAsync({
    [VerifyAccount.ACCESS_TOKEN]: accessToken,
  });

  switch (response.status) {
    case ServerResponse.OK: {
      return response?.payload ?? '';
    }
    default: {
      return '';
    }
  }
};

/**
 * A function utilized to authorize a user via the XQ `Authorize` service
 * @param user - the user's email or phone number
 * @param accessToken - an existing pre-auth or full auth dashboard access token
 * @param text - An interpolated text that is sent when a mobile number is specified. Use $pin to interpolate the pin or $link to interpolate the maybePayLoad.target
 * @param target - A link that can be interpolated in the maybePayload.text. Used for inviting users by text
 * @returns {Promise<ServerResponse<{payload:String}>>} a `ServerResponse` containing the access token
 */

export const authorizeUser = async ({
  user,
  accessToken,
  text,
  target,
  codetype,
}: {
  user: string;
  accessToken?: string;
  text?: string;
  target?: string;
  codetype?: string;
}) => {
  const response = await new Authorize(initializedXQSDK).supplyAsync({
    [Authorize.USER]: user,
    [Authorize.ACCESS_TOKEN]: accessToken,
    [Authorize.TEXT]: text,
    [Authorize.TARGET]: target,
    [Authorize.CODE_TYPE]: codetype,
  });
  switch (response.status) {
    case ServerResponse.OK: {
      // eslint-disable-next-line no-console
      console.log(`Successfully authorized user: ${user}`);
      return response;
    }
    case ServerResponse.ERROR: {
      console.error(`Unable to authorize user: ${user}`);
      return response;
    }
  }
};

/**
 * A function utilized to authorize a user via the XQ `AuthorizeAlias` service
 * @param email - the user's email
 * @returns {Promise<ServerResponse<{payload:String}>>} a `ServerResponse` containing the access token
 */
export const authorizeAliasUser = async (email: string) => {
  const authorizeAliasResponse = await new AuthorizeAlias(
    initializedXQSDK
  ).supplyAsync({
    [AuthorizeAlias.USER]: email,
  });
  switch (authorizeAliasResponse.status) {
    case ServerResponse.OK: {
      return authorizeAliasResponse;
    }
    case ServerResponse.ERROR: {
      // eslint-disable-next-line no-console
      console.log(`Unable to authorize user: ${email}`);
      return authorizeAliasResponse;
    }
  }
};

export const encryptMessage = async (
  message: string,
  recipients: string[],
  conversationId: string,
  messageId: string,
  messageExpiryTime: number,
  _onSuccess?: (encryptedText: string, locatorToken: string) => void,
  onFail?: () => void
) => {
  const OTPv2Algorithm = initializedXQSDK.getAlgorithm('OTP');

  /** The default metadata subject field used for event logs */
  const DEFAULT_METADATA_SUBJECT_FIELD = 'Secure Chat Message';

  const payload = {
    [Encrypt.TEXT]: message,
    [Encrypt.RECIPIENTS]: recipients,
    [Encrypt.EXPIRES_HOURS]: messageExpiryTime,
    [Encrypt.TYPE]: CommunicationsEnum.CHAT,
    [Encrypt.META]: {
      subject: DEFAULT_METADATA_SUBJECT_FIELD,
      conversationId,
      messageId,
    },
  };

  const response = await new Encrypt(
    initializedXQSDK,
    OTPv2Algorithm
  ).supplyAsync(payload);

  switch (response?.status) {
    case ServerResponse.OK: {
      const data = response.payload;
      const locatorToken: string = data[Encrypt.LOCATOR_KEY];
      const encryptedText: string = data[Encrypt.ENCRYPTED_TEXT];
      return { encryptedText, locatorToken };
    }
    case ServerResponse.ERROR: {
      if (onFail) {
        onFail();
      }
      break;
    }
  }
  return { encryptedText: '', locatorToken: '' };
};

export const decryptMessage = async (message: EncryptedMessageDocument) => {
  const OTPv2Algorithm = initializedXQSDK.getAlgorithm('OTP');

  const baseDecryptedMessagePayload: DecryptedMessagePayload = {
    expirationHours: baseMessageDocument.expirationHours,
    fileAttachment: baseMessageDocument.fileAttachment,
    text: baseMessageDocument.text,
    urlAttachments: baseMessageDocument.urlAttachments,
  };

  if (message.state === MessageState.DELETED) {
    return {
      ...baseDecryptedMessagePayload,
      text: 'This message has been deleted',
      status: 410,
    };
  }

  if (message.state === MessageState.EXPIRED) {
    return {
      ...baseDecryptedMessagePayload,
      text: 'This message has expired',
      status: 411,
    };
  }

  const checkKeyExpirationResponse = await new CheckKeyExpiration(
    initializedXQSDK
  ).supplyAsync({
    [FetchKey.LOCATOR_KEY]: message.locatorToken,
  });

  // Check if message is expired
  if (checkKeyExpirationResponse.payload.expiresOn <= 0) {
    // set fb state to expired
    await expireMessage(message.conversationId, message.id);

    return {
      ...baseDecryptedMessagePayload,
      text: 'This message has expired',
      status: 411,
    };
  }

  const decryptResponse = await new Decrypt(
    initializedXQSDK,
    OTPv2Algorithm
  ).supplyAsync({
    [Decrypt.ENCRYPTED_TEXT]: message.payload,
    [Decrypt.LOCATOR_KEY]: message.locatorToken,
  });

  switch (decryptResponse?.status) {
    case ServerResponse.OK: {
      const data = decryptResponse.payload;

      try {
        return JSON.parse(data[EncryptionAlgorithm.DECRYPTED_TEXT]);
      } catch (e) {
        return {
          ...baseDecryptedMessagePayload,
          text: 'There was an issue decrypting this message',
          status: 404,
        };
      }
    }
    case ServerResponse.ERROR: {
      break;
    }
  }

  return {
    ...baseDecryptedMessagePayload,
    text: 'There was an issue decrypting this message',
    status: 404,
  };
};

export const encryptFile = async (
  sourceFile: File,
  recipients: string[],
  messageExpiryTime: number
) => {
  const OTPv2Algorithm = initializedXQSDK.getAlgorithm('OTP');

  const encryptFileResponse = await new FileEncrypt(
    initializedXQSDK,
    OTPv2Algorithm
  ).supplyAsync({
    [FileEncrypt.DELETE_ON_RECEIPT]: false,
    [FileEncrypt.EXPIRES_HOURS]: messageExpiryTime,
    [FileEncrypt.RECIPIENTS]: recipients,
    [FileEncrypt.SOURCE_FILE]: sourceFile,
  });

  switch (encryptFileResponse?.status) {
    case ServerResponse.OK: {
      const encryptedFile = encryptFileResponse.payload as File;
      return encryptedFile;
    }
    case ServerResponse.ERROR: {
      return encryptFileResponse;
    }
  }
  return encryptFileResponse;
};

export const decryptFile = async (sourceFile: File) => {
  const OTPv2Algorithm = initializedXQSDK.getAlgorithm('OTP');

  const decryptFileResponse = await new FileDecrypt(
    initializedXQSDK,
    OTPv2Algorithm
  ).supplyAsync({
    [FileDecrypt.SOURCE_FILE]: sourceFile,
  });

  switch (decryptFileResponse?.status) {
    case ServerResponse.OK: {
      const decryptedFile = decryptFileResponse.payload as File;

      // TODO(worstestes - 3.15.22): replace this after SDK fix
      const TEMP_DECRYPTED_FILE = new File(
        [decryptedFile],
        sourceFile.name.slice(0, -4)
      );

      return TEMP_DECRYPTED_FILE;
    }
    case ServerResponse.ERROR: {
      return decryptFileResponse;
    }
  }
  return decryptFileResponse;
};

/**
 * Revokes key access of a specified locator key.
 * Only the user who sent the message will be able to revoke it.
 *
 * @param locatorKeys - a locator key string value
 */
export const revokeKeyAccess = async (locatorKeys: string[]) => {
  const revokeKeyResponse = await new RevokeKeyAccess(
    initializedXQSDK
  ).supplyAsync({
    tokens: locatorKeys,
  });

  return revokeKeyResponse;
};

/**
 * A service which is utilized to authenticate the two-factor PIN which resulted from the preceding {@link Authorize} service call.
 * If successful this service returns a `ServerResponse` containing the access token.
 * @param pin -  the two-factor pin used to validate the `Authorize` service request
 * @returns {Promise<ServerResponse<{payload:String}>>} a `ServerResponse` containing the access token
 */
export const codeValidator = async (pin: string) => {
  const codeValidatorResponse = await new CodeValidator(
    initializedXQSDK
  ).supplyAsync({
    [CodeValidator.PIN]: pin,
  });

  switch (codeValidatorResponse.status) {
    case ServerResponse.OK: {
      return codeValidatorResponse;
    }
    case ServerResponse.ERROR: {
      // eslint-disable-next-line no-console
      console.log(`Invalid Pin: ${pin}`);
      return codeValidatorResponse;
    }
  }
};

/**
 * A service which is utilized to validate a given dashboard session
 * @returns {Boolean} a boolean value representing the validity of the dashboard session
 */
export const validateSession = async () => {
  const validateSessionResponse = await new ValidateSession(
    initializedXQSDK
  ).supplyAsync({});

  switch (validateSessionResponse.status) {
    case ServerResponse.OK: {
      return true;
    }
    case ServerResponse.ERROR: {
      return false;
    }
    default:
      return false;
  }
};
