import { createSlice } from '@reduxjs/toolkit';
import {
  baseProcessedConversation,
  ProcessedConversation,
} from 'dashboard/models/Conversation';
import {
  baseProcessedMessageDocument,
  ProcessedMessage,
} from 'dashboard/models/Message';
import { EventLogItem } from 'event-log/types';

interface conversationsSliceState {
  conversationList: ProcessedConversation[];
  eventLogs: EventLogItem[];
  isLocatorTokenMode: boolean;
  selectedConversationId: string;
  selectedConversation: ProcessedConversation;
  selectedMessage: ProcessedMessage;
  selectedIncomingConversation: string;
  shouldLoadMoreMessages: boolean;
}

const initialState = () =>
  ({
    conversationList: [],
    eventLogs: [],
    isLocatorTokenMode: false,
    selectedConversationId: '',
    selectedConversation: baseProcessedConversation,
    selectedMessage: baseProcessedMessageDocument,
    selectedIncomingConversation: '',
    shouldLoadMoreMessages: false,
  }) as conversationsSliceState;

const conversationsSlice = createSlice({
  name: 'conversations',
  initialState: initialState(),
  reducers: {
    setConversationList(state, action) {
      let conversations = action.payload;

      if (conversations.length > 0) {
        conversations = conversations.sort(
          (
            conversationA: ProcessedConversation,
            conversationB: ProcessedConversation
          ) => {
            const first = conversationA.updatedAt
              ? conversationA.updatedAt
              : conversationA.createdAt;
            const second = conversationB.updatedAt
              ? conversationB.updatedAt
              : conversationB.createdAt;
            return second.seconds - first.seconds;
          }
        );
      }
      state.conversationList = conversations;
    },
    setEventLogs(state, action) {
      const eventLogs = action.payload;
      state.eventLogs = eventLogs;
    },
    setLocatorTokenMode(state, action) {
      const isLocatorTokenMode = action.payload;
      state.isLocatorTokenMode = isLocatorTokenMode;
    },
    setSelectedConversationById(state, action) {
      const selectedConversationId = action.payload;
      const selectedConversation =
        state.conversationList.find(
          (conversation) => conversation.id === selectedConversationId
        ) || baseProcessedConversation;

      state.selectedConversationId = selectedConversationId;
      state.selectedConversation =
        selectedConversation as ProcessedConversation;
    },
    setSelectedMessage(state, action) {
      const selectedMessage = action.payload;
      state.selectedMessage = selectedMessage;
    },
    setSelectedIncomingConversation(state, action) {
      const selectedIncomingConversation = action.payload;
      state.selectedIncomingConversation = selectedIncomingConversation;
    },
    setShouldLoadMoreMessages(state, action) {
      const shouldLoadMoreMessages = action.payload;
      state.shouldLoadMoreMessages = shouldLoadMoreMessages;
    },

    setSelectedConversationSettings(state, action) {
      const settings = action.payload;
      state.selectedConversation = {
        ...state.selectedConversation,
        ...settings,
      };
    },
    resetConversationState: () => initialState(),
  },
});

export const {
  setConversationList,
  setEventLogs,
  setLocatorTokenMode,
  setSelectedConversationById,
  setSelectedMessage,
  setSelectedIncomingConversation,
  resetConversationState,
  setSelectedConversationSettings,
  setShouldLoadMoreMessages,
} = conversationsSlice.actions;

export default conversationsSlice.reducer;
