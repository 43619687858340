import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import { env } from 'env';

import { User } from 'dashboard/models/User';

interface FirebaseConfigOptions {
  apiKey: string;
  appId: string;
  authDomain: string;
  measurementId: string;
  messagingSenderId: string;
  projectId: string;
  storageBucket: string;
}

/**
 * The Firebase app config Options object for the development environment used to enable the relevant environments firebase services
 */
const firebaseConfiguration: FirebaseConfigOptions = {
  apiKey: env.REACT_APP_FIREBASE_API_KEY ?? '',
  appId: env.REACT_APP_FIREBASE_APP_ID ?? '',
  authDomain: env.REACT_APP_FIREBASE_AUTH_DOMAIN ?? '',
  measurementId: env.REACT_APP_FIREBASE_MEASUREMENT_ID ?? '',
  messagingSenderId: env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID ?? '',
  projectId: env.REACT_APP_FIREBASE_PROJECT_ID ?? '',
  storageBucket: env.REACT_APP_FIREBASE_STORAGE_BUCKET ?? '',
};

/**
 * A function used to create and initialize a Firebase app instance
 * @returns `firebase.app.App`
 */
const firebaseInitializeApp = () => {
  if (firebase.apps.length === 0) {
    return firebase.initializeApp(firebaseConfiguration);
  }
};

firebaseInitializeApp();

export const FIREBASE_AUTH = firebase.auth();
export const FIREBASE_FIRESTORE = firebase.firestore();
export const FIREBASE_STORAGE = firebase.storage();

export enum AvailableCollections {
  USERS = 'users',
  CONVERSATIONS = 'conversations',
  FEEDBACK = 'feedback',
  MESSAGES = 'messages',
  WORKSPACES = 'workspaces',
}

export type FirestoreSort = 'asc' | 'desc';

export type FirestoreObserver = {
  next?: ((snapshot: FirestoreDocumentSnapshot) => void) | undefined;
  error?: ((error: firebase.firestore.FirestoreError) => void) | undefined;
  complete?: (() => void) | undefined;
};

/**
 * Firestore Document data (for use with DocumentReference.set()) consists of fields mapped to values.
 */
export type FirestoreDocumentData = firebase.firestore.DocumentData;

/**
 * A Timestamp generated by Firestore. The timestamp represents a point in time independent of
 * any time zone or calendar, represented as seconds and fractions of seconds
 * at nanosecond resolution in UTC Epoch time.
 */
export type FirestoreTimestamp = firebase.firestore.Timestamp;

/**
 * A Firestore `DocumentSnapshot` represents the results of a query
 */
export type FirestoreDocumentSnapshot =
  firebase.firestore.QuerySnapshot<FirestoreDocumentData>;

/**
 * A function utilized to set the timestamp in Firestore format.
 * @returns `Date` object
 */
export const setFirebaseTimestamp = (fromDate?: Date) => {
  if (fromDate) {
    return firebase.firestore.Timestamp.fromDate(fromDate);
  }

  return firebase.firestore.Timestamp.now();
};

/**
 * A utility function utilized to generate a document ID for a new Firestore document
 * @param selectedCollection - the selected Firestore collection
 * @returns
 */
export const createDocumentId = async (
  selectedCollection: AvailableCollections
) => {
  return FIREBASE_FIRESTORE.collection(selectedCollection).doc().id;
};

/**
 * A function utilized to fetch a user via their `id` field value within their `User` document
 * @param userId - the `User` document ID
 * @returns `Promise<User>`
 */
export const fetchUser = async (userId: string): Promise<User> => {
  const query = await FIREBASE_FIRESTORE.collection(AvailableCollections.USERS)
    .doc(userId)
    .get();

  const userData = query.data() as User;

  return userData;
};

/**
 * A function utilized to fetch a user via their `email` field value within their `User` document
 * @param email - the `User` email field value
 * @returns `Promise<User>`
 */
export async function getDocumentById(
  fieldType: 'id' | 'email',
  fieldValues: string[],
  collection: AvailableCollections
) {
  // don't run if there aren't any fieldValues or a collection for the collection
  if (!fieldValues || !fieldValues.length || !collection) return [];

  const collectionPath = FIREBASE_FIRESTORE.collection(collection);
  const batches = [];

  while (fieldValues.length) {
    // firestore limits batches to 10
    const batch = fieldValues.splice(0, 10);

    // add the batch request to to a queue
    batches.push(
      collectionPath
        .where(fieldType, 'in', [...batch])
        .get()
        .then((results) =>
          results.docs.map((result) => ({
            /* id: result.id, */ ...result.data(),
          }))
        )
    );
  }

  // after all of the data is fetched, return it
  const fetchedBatchData = await Promise.all(batches).then((content) =>
    content.flat()
  );

  return fetchedBatchData;
}

/**
 * A method utilized to create a document to be stored in a specified collection within Firestore
 * @param collectionPath - A slash-separated path to a collection.
 * @param documentPath - A slash-separated path to a document, ex. an `id` may be used.
 * @param documentData - A map of the fields and values for the document.
 */
export const createFirestoreDocument = (
  collectionPath: AvailableCollections,
  documentPath: string,
  documentData: firebase.firestore.DocumentData
) =>
  FIREBASE_FIRESTORE.collection(collectionPath)
    .doc(documentPath)
    .set(documentData);

/**
 * A method utilized to create a document to be stored in a specified collection within Firestore
 * @param parentCollectionPath - A slash-separated path to a collection.
 * @param parentDocumentPath - A slash-separated path to a document
 * @param subCollectionPath - A slash-separated path to a sub-collection.
 * @param subDocumentPath - A slash-separated path to a sub-collection document
 * @param documentData - A map of the fields and values for the document.
 */
export const createFirestoreSubCollectionDocument = (
  parentCollectionPath: AvailableCollections,
  parentDocumentPath: string,
  subCollectionPath: AvailableCollections,
  subDocumentPath: string,
  documentData: firebase.firestore.DocumentData
) =>
  FIREBASE_FIRESTORE.collection(parentCollectionPath)
    .doc(parentDocumentPath)
    .collection(subCollectionPath)
    .doc(subDocumentPath)
    .set(documentData);

/**
 * A method utilized to update a document stored in a specified collection within Firestore
 * @param collectionPath - A slash-separated path to a collection.
 * @param documentPath - A slash-separated path to a document, ex. an `id` may be used.
 * @param documentData - A map of the fields and values for the document.
 */
export const updateFirestoreDocument = (
  collectionPath: AvailableCollections,
  documentPath: string,
  documentData: firebase.firestore.DocumentData
) =>
  FIREBASE_FIRESTORE.collection(collectionPath)
    .doc(documentPath)
    .set(documentData, { merge: true });

/**
 * Uses the `FieldValue` method of `increment` provided by Firestore.
 * Returns a special value that can be used with set() or update() that tells the server
 * to increment the field's current value by the given value.
 * @returns `number` (type cast)
 */
export const incrementFieldValue = () => {
  return firebase.firestore.FieldValue.increment(1) as unknown as number;
};

export const isUserSignedInAnonymously = (): boolean => {
  const isAuthUserSignedInAnonymously = !!FIREBASE_AUTH.currentUser;

  return isAuthUserSignedInAnonymously;
};
