import { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import authController, { isValidSession } from 'auth/AuthenticationController';
import routes from 'routes';
import { useDispatch } from 'react-redux';
import { setSelectedIncomingConversation } from 'store/reducers/conversations';
import { useToast } from '@chakra-ui/react';

export enum Status {
  Initial,
  Checking,
  Complete,
  Invalid,
}

export function useLoginWithLink(type?: string) {
  const location = useLocation();
  const history = useHistory();
  const qs = useRef(new URLSearchParams(location.search.substr(1)));
  const [status, setStatus] = useState<Status>(Status.Initial);
  const dispatch = useDispatch();
  const toast = useToast();

  useEffect(() => {
    if (status === Status.Invalid) {
      toast({
        description:
          'The link you used has expired please login with your email or phone number to continue the conversation.',
        status: 'error',
        position: 'bottom-right',
      });
      return history.push(routes.auth.login);
    }
  }, [history, status, toast]);

  useEffect(() => {
    let token = qs.current.get('access_token') as string;
    const convoId = qs.current.get('conversationId');

    if (!token) {
      return;
    }

    if (convoId) {
      dispatch(setSelectedIncomingConversation(convoId));
    }

    const run = async () => {
      const lastUsedToken = sessionStorage.getItem('lastUsedToken');
      if (status === Status.Initial && lastUsedToken !== token) {
        sessionStorage.setItem('lastUsedToken', token);
        setStatus(Status.Checking);

        try {
          if (type === 'sms') {
            token = await authController.exchangeForDashboardAccessToken(token);
          } else {
            await authController.loginViaMagicLink(token);
          }

          const hasAccessToDashboard = await isValidSession();

          if (!hasAccessToDashboard) {
            return setStatus(Status.Invalid);
          }

          // Go back to where the user originally wanted to go and then clear the state
          setTimeout(() => {
            setStatus(Status.Complete);

            const conversationId = qs.current.get('conversationId') ?? '';
            sessionStorage.removeItem('lastUsedToken');
            history.push({
              pathname: routes.home,
              search:
                conversationId !== ''
                  ? '?conversationId=' + conversationId
                  : '',
            });
          }, 3000);
        } catch (e) {
          setStatus(Status.Invalid);
        }
      }
    };
    run();
  }, [dispatch, history, type, status]);
  return {
    hasToken: qs.current.has('access_token'),
    status,
  };
}
