import axios from 'axios';
import { env } from 'env';
import { URLAttachment } from 'dashboard/models/Message';

const cloudFunctionsWebScraperURL = `${env.REACT_APP_CLOUD_FUNCTIONS_HOST}/scraper`;

export const detectURLs = (message: string) => {
  const urlRegex =
    /(https?:\/\/)?[\w\-~]+(\.[\w\-~]+)+(\/[\w\-~@:%]*)*(#[\w-]*)?(\?[^\s]*)?/gi;
  const foundURL = message.match(urlRegex);

  if (foundURL) {
    return foundURL;
  }

  const urlWithoutPrefix = /^([a-zA-Z0-9]+(\.[a-zA-Z0-9]+)+.*)$/;

  const foundURLWithoutPrefix = message.match(urlWithoutPrefix);

  return foundURLWithoutPrefix;
};

export const fetchURLsMetadata = async (
  detectedUrls: string[]
): Promise<URLAttachment[] | []> =>
  axios
    .post(cloudFunctionsWebScraperURL, {
      urls: detectedUrls,
    })
    .then((res) => res.data)
    .catch((e) => {
      console.error(e);
      return [];
    });
