import { useCallback, useEffect, useState } from 'react';
import { getEventLogs } from 'dashboard/services/XQSDK';
import { EventLogItem } from '../../event-log/types';

export const useGetTokenEventLogs = (locatorToken: string) => {
  const [logs, setLogs] = useState<EventLogItem[]>([]);
  const [loaded, setLoaded] = useState(false);

  const getLogs = useCallback(async () => {
    const eventLog = await getEventLogs(locatorToken);

    return eventLog;
  }, [locatorToken]);

  useEffect(() => {
    setLogs([]);
    getLogs().then((processedLogs) => {
      setLogs(processedLogs);
      setLoaded(true);
    });
  }, [getLogs]);
  return { logs, loaded };
};
