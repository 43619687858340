import { createSlice } from '@reduxjs/toolkit';
import getWindowDimensions from 'dashboard/features/Thread/utils/getWindowDimensions';
import { DeviceType } from 'dashboard/models/Device';

export enum MobileViewOptions {
  ADD_NAME = 'add-name',
  COMPOSE = 'compose',
  CONVERSATION_LIST = 'conversation-list',
  THREAD = 'thread',
  LOADING = 'loading',
  LOADING_CONVERSATION = 'loading-conversation',
  SECURITY = 'security',
  PROFILE = 'profile',
  CONVERSATION_SETTINGS = 'conversation-settings',
}

export enum DesktopViewOptions {
  ADD_NAME = 'add-name',
  COMPOSE = 'compose',
  EMPTY_STATE = 'empty-state',
  FEEDBACK_FORM = 'feedback-form',
  LOADING = 'loading',
  LOADING_CONVERSATION = 'loading-conversation',
  THREAD = 'thread',
}

export enum SidebarViewOptions {
  CONVERSATION_LIST = 'conversation-list',
  PROFILE = 'profile',
  SECURITY = 'security',
  CONVERSATION_SETTINGS = 'conversation-settings',
}

export type CurrentView = {
  desktop: {
    main: DesktopViewOptions;
    sidebar: SidebarViewOptions;
  };
  mobile: MobileViewOptions;
};

export interface applicationSliceState {
  currentDeviceType: DeviceType;
  currentView: CurrentView;
  isFeedbackMode: boolean;
  messageListViewportHeight: number;
  threadOuterHeight: number;
}

const initialState = () =>
  ({
    currentDeviceType: DeviceType.DESKTOP,
    currentView: {
      desktop: {
        main: DesktopViewOptions.LOADING,
        sidebar: SidebarViewOptions.CONVERSATION_LIST,
      },
      mobile: MobileViewOptions.LOADING,
    },
    isFeedbackMode: false,
    messageListViewportHeight: getWindowDimensions().height * 0.85,
    // threadOuterHeight is a patch for mobile browsers having jerky input fields onFocus
    threadOuterHeight: getWindowDimensions().height,
  }) as applicationSliceState;

const applicationSlice = createSlice({
  name: 'application',
  initialState: initialState(),
  reducers: {
    setCurrentDeviceType(state, action) {
      const currentDeviceType = action.payload;
      state.currentDeviceType = currentDeviceType;
    },
    setDesktopView(state, action) {
      const currentDesktopView = action.payload;
      state.currentView.desktop.main = currentDesktopView;
    },
    setSidebarView(state, action) {
      const currentSidebarView = action.payload;
      state.currentView.desktop.sidebar = currentSidebarView;
    },
    setMobileView(state, action) {
      const currentMobileView = action.payload;
      state.currentView.mobile = currentMobileView;
    },
    setFeedbackMode(state, action) {
      const isFeedbackMode = action.payload;
      state.isFeedbackMode = isFeedbackMode;
    },
    setMessageListViewportHeight(state, action) {
      const messageListViewportHeight = action.payload;
      state.messageListViewportHeight = messageListViewportHeight;
    },
    setThreadOuterHeight(state, action) {
      const threadOuterHeight = action.payload;
      state.threadOuterHeight = threadOuterHeight;
    },
    resetApplicationState(state) {
      state.currentView.desktop.main = DesktopViewOptions.LOADING;
      state.currentView.desktop.sidebar = SidebarViewOptions.CONVERSATION_LIST;
      state.currentView.mobile = MobileViewOptions.LOADING;
      state.isFeedbackMode = false;
      state.messageListViewportHeight = getWindowDimensions().height * 0.85;
    },
  },
});

export const {
  resetApplicationState,
  setCurrentDeviceType,
  setDesktopView,
  setFeedbackMode,
  setMessageListViewportHeight,
  setMobileView,
  setSidebarView,
  setThreadOuterHeight,
} = applicationSlice.actions;

export default applicationSlice.reducer;
