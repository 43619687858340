import { Box, Flex } from '@chakra-ui/react';

import React from 'react';
import routes from 'routes';
import ReactGA from 'react-ga';
import { env } from 'env';

import LinkGroup from 'dashboard/features/NavigationSidebar/LinkGroup';

import { ReactComponent as StaticMailLogo } from 'assets/svg/static-email.svg';
import { ReactComponent as StaticChatLogo } from 'assets/svg/static-chat.svg';
import { ReactComponent as StaticHelpLogo } from 'assets/svg/static-help.svg';
import { ReactComponent as StaticWorkLogo } from 'assets/svg/static-workspace.svg';
import { ReactComponent as ClickedMailLogo } from 'assets/svg/clicked-email.svg';
import { ReactComponent as ClickedChatLogo } from 'assets/svg/clicked-chat.svg';
import { ReactComponent as ClickedHelpLogo } from 'assets/svg/clicked-help.svg';
import { ReactComponent as ClickedWorkLogo } from 'assets/svg/clicked-workspace.svg';
import { ReactComponent as StaticFeedbackLogo } from 'assets/svg/static-feedback.svg';
import { ReactComponent as ClickedFeedbackLogo } from 'assets/svg/clicked-feedback.svg';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { setFeedbackMode } from 'store/reducers/application';
import { useIsLargerThan980 } from 'dashboard/hooks/useDeviceType';
import '../../scss/main.scss';
import './NavigationSidebar.scss';

import xqLogo from 'assets/xq-logo-k.png';

// eslint-disable-next-line react/function-component-definition
const NavigationSidebar: React.FC = () => {
  /**
   * HACK:
   * There is a bug in Chakra where the "Collapse" component doesn't render properly
   * when it is "true" from the get go. That means the all child routes are inaccessible
   * and hidden when a user refreshes there screen. To get around this, there is blip where
   * no subRoutes are defined, and they get defined after the first render.
   *
   * Once this is fixed in Chakra, we can remove this hack.
   */

  const dispatch = useDispatch();

  const {
    application: { isFeedbackMode },
  } = useSelector((state: RootState) => ({
    application: state.application,
    currentUser: state.user.currentUser,
  }));

  // Check to see if the onSelectConversation element inside the navigation is a link so the
  // mobile drawer can be automatically closed.
  const handleWrapperClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const el = e.target as HTMLElement;
    const link = el.closest('a');
    ReactGA.ga('clientTracker.send', 'event', {
      eventCategory: 'Navigation',
      eventAction: 'Selection',
      eventLabel: link ? link.innerText : el.innerText,
    });
  };

  return (
    <Box
      id="navigation-sidebar-container"
      height={useIsLargerThan980() ? '100vh' : 'unset'}
    >
      <Flex
        flex={1}
        flexDirection="column"
        pt={2}
        overflowY="auto"
        className="scrollbar-without-transition"
        css={{
          WebkitOverflowScrolling: 'touch',
        }}
        style={{ position: 'relative' }}
        onClick={handleWrapperClick}
      >
        <Box
          flex="none"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          <Flex
            justifyContent="space-between"
            flexDirection="column"
            height="100%"
          >
            <Box>
              <Flex alignItems="center" justifyContent="center" mt={15} mb={35}>
                <img
                  aria-label="xq-logo"
                  src={xqLogo}
                  style={{ width: 60, height: 30 }}
                />
              </Flex>
              <LinkGroup
                to={routes.home}
                title="Chat"
                StaticIcon={StaticChatLogo}
                ClickedIcon={ClickedChatLogo}
                isStatic={isFeedbackMode}
                onClick={() =>
                  isFeedbackMode ? dispatch(setFeedbackMode(false)) : null
                }
              />
              <LinkGroup
                href={`${
                  env.REACT_APP_MANAGE_HOST ?? 'https://manage.xqmsg.com'
                }/encryption/encrypt-message`}
                title="Email"
                StaticIcon={StaticMailLogo}
                ClickedIcon={ClickedMailLogo}
                isExternal
              />
              <LinkGroup
                href={`${
                  env.REACT_APP_MANAGE_HOST ?? 'https://manage.xqmsg.com'
                }/communications/user`}
                title="Workspace"
                StaticIcon={StaticWorkLogo}
                ClickedIcon={ClickedWorkLogo}
                isExternal
              />
              <LinkGroup
                href="https://docs.xqmsg.com/knowledge"
                title="Help"
                StaticIcon={StaticHelpLogo}
                ClickedIcon={ClickedHelpLogo}
                isExternal
              />
            </Box>
            <Flex
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              mb={15}
              cursor="pointer"
              onClick={() => dispatch(setFeedbackMode(!isFeedbackMode))}
            >
              {isFeedbackMode ? (
                <ClickedFeedbackLogo className="feedback-icon" />
              ) : (
                <StaticFeedbackLogo className="feedback-icon static" />
              )}
              <p className="feedback-text">Feedback</p>
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default NavigationSidebar;
